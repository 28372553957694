import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
import {getLocalStorage} from "@/utils/storage";

// 组织架构
export const listOrganization = (tenantId) => {
    return fetchApi(`${URL.ORGANIZATION_LIST}?tenantId=${tenantId}`, {}, "GET");
};

// 获取列表数据
export const getAll = (organizationId, pageNum, pageSize) => {
    return fetchApi(`${URL.ORGANIZATION_TABLE}?organizationId=${organizationId}&pageNum=${pageNum}&pageSize=${pageSize}`, {}, "post", "data")
}

// 新建组织
export const organizationAdd = (organizationRequest) => {
    return fetchApi(`${URL.ORGANIZATION_ADD}`, {...organizationRequest}, "post", "data")
}

// 编辑组织
export const organizationEdit = (organizationRequest) => {
    return fetchApi(`${URL.ORGANIZATION_EDIT}`, {...organizationRequest}, "post", "data")
}

// 查询上级组织
export const getParentNameByOrganizationId = (organizationId) => {
    return fetchApi(`${URL.ORGANIZATION_SEARCHID}?organizationId=${organizationId}`, {}, "get")
}

// 组织详情
export const getInfo = (organizationId, parentId) => {
    return fetchApi(`${URL.ORGANIZATION_DETAIL}?organizationId=${organizationId}&parentId=${parentId}`, {}, "get")
}

// 已关联和未关联组织人员
export const getUserList = (organizationRequest) => {
    return fetchApi(`${URL.ORGANIZATION_USERLIST}`, {...organizationRequest}, "post","data")
}

// 删除组织
export const organizationDelete = (organizationId) => {
    return fetchApi(`${URL.ORGANIZATION_DELETE}?organizationId=${organizationId}`, {}, "post","data")
}

// 添加用户组织关联
export const organizationAddUserOrg = (organizationRequest) => {
    return fetchApi(`${URL.ORGANIZATION_ADDUSERORG}`, {...organizationRequest}, "post","data")
}

// 获取租户id
export const tenantId = (organizationId) => {
    return fetchApi(`${URL.ORGANIZATION_TENANTID}?organizationId=${organizationId}`, {}, "get")
}

// 获取负责人
export const selectSuperintendent = () => {
    const tenantId = getLocalStorage("user").tenantId
    return fetchApi(`/api/dscloud-saas-platform/organization/getUserListByTenantId?tenantId=${tenantId}`)
}
