<template>
  <div class="organizationManage">
    <ds-header title="组织管理"></ds-header>
    <div class="main">
      <div class="main-left" style="margin-top: 15px">
        <p style="font-weight:700">组织架构</p>
        <div>
          <a-tree
            :tree-data="treeData"
            :replace-fields="replaceFields"
            :default-expand-all="true"
            defaultExpandParent
            @select="onSelect"
            @check="onCheck"
          />
        </div>
      </div>
      <div class="main-right" style="margin-top: 15px">
        <div v-if="$checkButtonPermissions($buttonPermissionsConfig.authManage.organizationManage.add)">
          <a-button type="primary" @click="addOrg">
            新建组织
          </a-button>
        </div>
        <div style="padding-top: 15px">
          <a-table :columns="table.columns" :data-source="table.data" :pagination="false">
            <span slot="action" slot-scope="record">
              <a style="color:#1890FF" @click="edit(record)" v-if="$checkButtonPermissions($buttonPermissionsConfig.authManage.organizationManage.edit)">编辑</a>&nbsp;&nbsp;
              <a style="color:#1890FF" @click="cruxUser(record)" v-if="$checkButtonPermissions($buttonPermissionsConfig.authManage.organizationManage.relevanceUser)">关联用户</a>&nbsp;&nbsp;
              <a style="color:#1890FF" @click="deleteBtn(record)" v-if="$checkButtonPermissions($buttonPermissionsConfig.authManage.organizationManage.delete)">删除</a>
            </span>
          </a-table>
        </div>
        <div v-if="total !== 0" style="margin-right: 20px; margin-top: 50px; text-align:right">
          <a-pagination
            size="small"
            @change="changePage"
            :total="total"
            show-size-changer
            show-quick-jumper
            :page-size-options="['10', '20', '30', '40']"
            :show-total="(total) => `共 ${total} 条`"
            :current="page"
            @showSizeChange="onShowSizeChange"
          />
        </div>
      </div>
    </div>
    <a-modal v-model="visible" :title="`${form.organizationId ? '编辑' : '新建'}组织`" @ok="sureBtn" @cancel="cancelBtn">
        <a-form-model ref="ruleForms" :model="form" :rules="rules">
          <a-form-model-item label="上级组织" :labelCol="{span: 5}" :wrapperCol="{span: 17}" prop="parentId" style="margin-top:20px">
            {{parentName}}
          </a-form-model-item>
          <a-form-model-item label="组织名称" :labelCol="{span: 5}" :wrapperCol="{span: 17}" prop="organizationName" style="margin-top:20px">
              <a-input v-model.trim="form.organizationName" placeholder="请输入组织名称"></a-input>
          </a-form-model-item>
          <a-form-model-item label="组织分级" :labelCol="{span: 5}" :wrapperCol="{span: 17}" prop="orgType">
            <a-select
              v-model="form.orgType"
              placeholder="请选择组织分级"
            >
              <a-select-option :value="0">上级部门</a-select-option>
              <a-select-option :value="1">下属部门</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="组织描述" :labelCol="{span: 5}" :wrapperCol="{span: 17}" prop="remark" style="margin-top:20px">
            <a-textarea placeholder="请输入组织描述" v-model.trim="form.remark" :rows="4" />
          </a-form-model-item>
          <a-form-model-item label="负责人" :labelCol="{span: 5}" :wrapperCol="{span: 17}" prop="userId" style="margin-top:20px">
            <a-select
              show-search
              v-model="form.userId"
              placeholder="请选择负责人"
              :filterOption="filterNickName"
              @change="selectedPrincipal"
            >
              <a-select-option v-for="item in superintendentList" :key="item.userId" :value="item.userId">
                {{ item.nickname }}
              </a-select-option>

            </a-select>
          </a-form-model-item>
          <a-form-model-item label="联系方式" :labelCol="{span: 5}" :wrapperCol="{span: 17}" prop="phone" style="margin-top:20px">
              <!-- <a-input :maxLength="11" v-model.trim="form.phone" placeholder="请输入联系方式"></a-input>÷ -->
               {{ form.phone || '-' }}
          </a-form-model-item>
        </a-form-model>
    </a-modal>
      <a-modal v-model="visibleUser" title="关联账户" @ok="cruxUserBtn">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-form-model-item label="组织机构" :labelCol="{span: 5}" :wrapperCol="{span: 17}" prop="productName">
              {{organizationName}}
          </a-form-model-item>
          <a-form-model-item>
            <a-transfer
              :titles="['可关联用户', '已关联用户']"
              v-model="targetKeys"
              style="margin-left:20px"
              :data-source="mockData"
              show-search
              :filter-option="filterOption"
              :target-keys="targetKeys"
              :render="item => item.title"
              @change="handleChanges"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <ConfirmBoxModule ref="confirmBoxModule"></ConfirmBoxModule>
  </div>
</template>

<script>
import * as api from "@/api/authManage/organizationManage";
import { getLocalStorage } from "@/utils/storage";
import ConfirmBoxModule from "@/components/confirmBoxModule";
import ConfirmConfig from "@/utils/confirmConfig";
import {selectSuperintendent} from "@/api/authManage/organizationManage";
export default {
  name: "OrganizationManage",
  components: {
    ConfirmBoxModule
  },
  data(){
    return {
      // 负责人
      superintendentList: [],
      table:{
        columns:[
          {
            title: '组织名称',
            dataIndex: 'organizationName',
            key: 'organizationName',
          },
          {
            title: '组织分级',
            dataIndex: 'orgType',
            customRender(text) {
              return text === 0 ? '上级部门' : text === 1 ? '下属组织' : '-'
            }
          },
          {
            title: '上级组织',
            dataIndex: 'parentName',
            key: 'parentName',
          },
          {
            title: '负责人',
            dataIndex: 'userName',
            key: 'userName',
          },
          {
            title: '电话',
            key: 'phone',
            dataIndex: 'phone',
          },
          {
            title: '组织人数',
            key: 'organizationCount',
            dataIndex: 'organizationCount',
          },
        ],
        data:[]
      },
      page: 1,
      size: 10,
      total: 0,
      treeData:[],
      replaceFields: {
        children: 'children',
        title: 'organizationName',
        key: 'organizationId'
      },
      visible: false,
      visibleUser: false,
      // visibleDelete: false,
      mockData:[],
      targetKeys: [],
      parentName:"",
      form:{
        tenantId:"",
        parentId:"",
        organizationName:"",
        // 0：上级部门 1：下属组织
        orgType: undefined,
        remark:"",
        userId: undefined,
        phone:"",
      },
      rules:{
        // parentId: [{ required: true, message: '请选择上级组织!', tigger: 'blur' }],
        organizationName: [{ required: true, message: '请输入组织名称!', tigger: 'blur' },
        { max: 50, message: "组织名称最多可输入50个字符", trigger: "blur" },],
        orgType: [
          { required: true, message: '请选择组织分级', trigger: 'change' }
        ],
        remark: [{ max: 100, message: "组织描述最多可输入100个字符", trigger: "blur" },],
        userId: [{ required: true, message: '请选择负责人!', tigger: 'change' },
        { max: 50, message: "负责人最多可输入50个字符", trigger: "blur" },],
        // phone: [{ required: true, message: '请输入联系方式!', tigger: 'blur' },
        // { pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: "请输入正确的手机号", trigger: "blur" },],
      },
      organizationId:"",
      flag:0,
      organizationName:"",
      deleteValue:{},
      cruxUserData:{},
      tenantId: getLocalStorage("user").tenantId,
      //操作集合
      actions: [
        {
          permissions: this.$buttonPermissionsConfig.authManage.organizationManage.edit
        },
        {
          permissions: this.$buttonPermissionsConfig.authManage.organizationManage.relevanceUser
        },
        {
          permissions: this.$buttonPermissionsConfig.authManage.organizationManage.delete
        },
      ],
    }
  },
  mounted(){
    // this.organizationId = "bae69ce3ec9d"
    this.listOrganization()
    this.listOrganizationAll()
    this.getAll()
  },
  methods: {
    // 选中负责人
    selectedPrincipal(userId) {
      this.form.phone = this.superintendentList.filter(item => item.userId === userId)[0].phoneNumber;
    },
    // 负责人的搜索
    filterNickName(inputValue, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    },
    // 组织架构
    async listOrganization(){
      const res = await api.listOrganization(this.tenantId)
      this.treeData = res.data
      // this.form.parentId = res.data[0].organizationId
      // this.organizationId = res.data[0].organizationId
      // this.parentName = res.data[0].organizationName
      const resid = await api.tenantId(this.organizationId)
      if(resid.code == "200"){
        this.form.tenantId = resid.data
      }
      this.getAll()
    },
    async listOrganizationAll(){
      const res = await api.listOrganization(this.tenantId)
      this.treeData = res.data
      this.form.parentId = res.data[0].organizationId
      this.organizationId = res.data[0].organizationId
      this.parentName = res.data[0].organizationName
      const resid = await api.tenantId(res.data[0].organizationId)
      if(resid.code == "200"){
        this.form.tenantId = resid.data
      }
      this.getAll()
    },
    // 列表数据
    async getAll(){
      const res = await api.getAll(this.organizationId,this.page,this.size)
      this.table.data = res.data.list
      this.total = res.data.total
    },
    // 树形结构选择
    async onSelect(selectedKeys,info){
      if(info.selected){
        this.form.parentId = selectedKeys[0]
        this.organizationId = selectedKeys[0]
        this.parentName = info.selectedNodes[0].data.props.dataRef.organizationName
        const res = await api.tenantId(selectedKeys[0])
        if(res.code == "200"){
          this.form.tenantId = res.data
        }
      }
      this.getAll()
    },
    onCheck(checkedKeys, info) {
      console.log('onCheck', checkedKeys, info);
    },
    // 编辑
    async edit(value){
      // this.listOrganization()
      this.flag = 1
      const res1 = await api.getParentNameByOrganizationId(value.organizationId) // 上级组织
      this.parentName = res1.data && res1.data.split(",")[1]
      this.form.parentId = value.parentId
      const res = await api.getInfo(value.organizationId,value.parentId)
      this.form = res.data
      this.visible = true
    },
    // 关联用户
    async cruxUser(value){
      this.cruxUserData = value
      this.organizationName = value.organizationName
      const organizationRequest = {
        organizationId: value.organizationId,
        organizationName: value.organizationName,
        parentId: value.parentId,
        parentName: value.parentName,
        // tenantId:"03ba723cf0800000",
      }
      this.mockData = []
      this.targetKeys = []
      const res = await api.getUserList(organizationRequest)
      res.data.associatedUserRequestArrayList.map((item) => {
          this.mockData.push({
            key: item.userId,
            title: item.userName,
            description: item.userName,
            chosen: Math.random() * 2 > 1,
          })
        })
        res.data.relevanceUserSet.map((item) => {
          this.mockData.push({
            key: item.hasBeenAssociatedUserId,
            title: item.hasBeenAssociatedUserName,
            description: item.hasBeenAssociatedUserName,
            chosen: Math.random() * 2 > 1,
          })
          this.targetKeys.push(item.hasBeenAssociatedUserId)
        })
      this.visibleUser = true
    },
    async deleteBtn(value){
      // this.visibleDelete = true
      // const that = this;
      this.deleteValue = value
      // this.$confirm({
      //   title: "是否确认删除该组织?",
      //   content: () => <div style="color:red;">删除后组织信息及组织下所有信息将同步删除！</div>,
      //   icon: () => <a-icon type="close-circle" style="color: red" />,
      //   onOk() {
      //     that.deleteSure();
      //   },
      //   onCancel() {},
      //   class: "test",
      // });
      await this.$refs.confirmBoxModule.show({
        title:'是否确认删除该组织?',
        content:"删除后组织信息及组织下所有信息将同步删除！",
        type:ConfirmConfig.CONFIRM_TYPE_ERROR
      });
      this.deleteSure();
    },
    async deleteSure(){
      const res = await api.organizationDelete(this.deleteValue.organizationId)
      if(res.code === "200"){
        this.$message.success("删除成功")
        // this.visibleDelete = false
        this.listOrganization()
        this.getAll()
      }
    },
    // 关联用户
    async cruxUserBtn(){
      const associatedUserRequestArrayList = []
      this.mockData.map(item => {
        this.targetKeys.map(ite => {
          if(item.key === ite){
            associatedUserRequestArrayList.push({
              userId:item.key,
              userName:item.title
            })
          }
        })
      })
      this.cruxUserData.organizationId
      const data = {
        organizationId: this.cruxUserData.organizationId,
        associatedUserRequestArrayList:associatedUserRequestArrayList,
      }
      const res = await api.organizationAddUserOrg(data)
      if(res.code === "200"){
        this.$message.success("关联成功")
        this.getAll()
        this.visibleUser = false
      }
    },
    // 关闭弹窗
    cancelBtn(){
      this.form = {
        parentId:this.form.parentId,
        organizationName:"",
        remark:"",
        userName:"",
        phone:"",
      }
      if(this.$refs.ruleForms){
        this.$refs.ruleForms.resetFields()
      }
    },
    // 保存
    sureBtn(){
      if(this.flag === 1){//编辑保存
        this.$refs.ruleForms.validate(async valid => {
          if (valid) {
            const user = this.superintendentList.find(item => item.userId === this.form.userId)
            const organizationRequest = {
              // organizationId: this.organizationId,
              ...this.form,
              userName: user.nickname || ''
            }
            const res = await api.organizationEdit(organizationRequest)
            if(res.code === "200"){
              this.$message.success("编辑成功")
              this.visible = false
              this.listOrganization()
              this.getAll()
              this.form = {
                parentId:this.form.parentId,
                organizationName:"",
                remark:"",
                userName:"",
                phone:"",
              }
            }else{
              this.$message.error(res.msg)
            }
          } else {
            return false;
          }
        });
      }else{//新增保存
        this.$refs.ruleForms.validate(async valid => {
          if (valid) {
            const user = this.superintendentList.find(item => item.userId === this.form.userId)
            const organizationRequest = {
              organizationId: this.organizationId,
              ...this.form,
              userName: user.nickname || ''
            }
            const res = await api.organizationAdd(organizationRequest)
            if(res.code === "200"){
              this.$message.success("添加成功")
              this.visible = false
              this.listOrganization()
              this.getAll()
              this.form = {
                parentId:this.form.parentId,
                organizationName:"",
                remark:"",
                userName:"",
                phone:"",
              }
            }else{
              this.$message.error(res.msg)
            }
          } else {
            return false;
          }
        });
      }
    },
    // 添加组织
    async addOrg(){
      this.flag = 2
      // const res = await api.getParentNameByOrganizationId(this.organizationId)
      // this.parentName = res.data && res.data.split(",")[1]
      // this.form.parentId = res.data && res.data.split(",")[0]
      this.visible = true
    },
    filterOption(inputValue, option) {
      return option.description.indexOf(inputValue) > -1;
    },
    handleChanges(targetKeys) {
      this.form.targetKeys = targetKeys;
      this.targetKeys = targetKeys
    },
    changePage (page) {
      this.page = page
      this.getAll()
    },
    onShowSizeChange(current, pageSize) {
      this.page = 1
      this.size = pageSize;
      this.getAll()
    },
    // 获取负责人信息
    async getSuperintendent() {
      const res = await selectSuperintendent()
      const { code, data, msg } = res
      if(code === '200') {
        this.superintendentList = data
      } else {
        this.$message.error(msg)
      }
    }
  },
  created() {
    // 获取负责人
    this.getSuperintendent()
    this.$checkTableActionIsShow(this.actions) && this.table.columns.push(this.$backTableAction());
  },
};
</script>

<style lang="scss" scoped>
.main{
  display: flex;
  justify-content: flex-start;
}
.main-left{
  width:29%;
  height: 100%;
  border-right:1px solid #f4f4f4;
}
.main-right{
  width:69%;
  margin-left:2%;
}
::v-deep .ant-tree li .ant-tree-node-content-wrapper{
  width:80%;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap
}
// /deep/.ant-tree-treenode-switcher-close{
//   font-weight: 600
// }
// /deep/.ant-tree-treenode-switcher-open{
//   font-weight: 600
// }
// /deep/.ant-tree-node-content-wrapper .ant-tree-node-content-wrapper-normal{
//   .ant-tree-title{
//     font-weight: 500 !important
//   }
// }
::v-deep .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  font-weight: 700;
  background-color: white;
}
::v-deep .ant-form-item{
  margin-top:13px !important;
  margin-bottom: 0;
}
</style>
